// Generated by Framer (9045c1a)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import Globe from "https://framerusercontent.com/modules/xFhv3ETdRBLjJusGaZ1P/SY4EOdo4KxEvLISnWiR3/Globe.js";
const GlobeFonts = getFonts(Globe);

const serializationHash = "framer-cIaWs"

const variantClassNames = {ncxR4PfWj: "framer-v-1qj74lz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ncxR4PfWj", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1qj74lz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ncxR4PfWj"} ref={refBinding} style={{...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-13594r3-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"IvXTruywM-container"} nodeId={"IvXTruywM"} rendersWithMotion scopeId={"Hk_Eo9quD"} style={{mask: "linear-gradient(0deg, rgba(0,0,0,0) 27%, rgba(0,0,0,1) 68%) add", opacity: 0.5, WebkitMask: "linear-gradient(0deg, rgba(0,0,0,0) 27%, rgba(0,0,0,1) 68%) add"}}><Globe alignment={"center"} background={"rgba(255, 255, 255, 0)"} baseColor={"rgb(227, 225, 225)"} dark={1} diffuse={2.9} dragOptions={{damping: 40, mass: 1, stiffness: 400}} glowColor={"rgb(255, 255, 255)"} height={"100%"} id={"IvXTruywM"} isDraggable layoutId={"IvXTruywM"} mapBrightness={12} markerArray={[{latitude: 65.875, longitude: 27.1803}, {latitude: 23.6899, longitude: -81.887}, {latitude: 51.4099, longitude: 30.101}, {latitude: 24.3676, longitude: 55.9041}, {latitude: 40.6899, longitude: -75.887}]} markerColor={"rgb(0, 157, 255)"} markerSize={0.04} maxSamples={10000} maxWidth={1200} offset={{offsetX: 0, offsetY: 0}} phi={4.5} scale={1} speed={0.6} style={{height: "100%", width: "100%"}} theta={0.4} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cIaWs.framer-1fifehv, .framer-cIaWs .framer-1fifehv { display: block; }", ".framer-cIaWs.framer-1qj74lz { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 500px; justify-content: flex-start; max-width: 1420px; overflow: hidden; padding: 0px; position: relative; width: 800px; }", ".framer-cIaWs .framer-13594r3-container { aspect-ratio: 1.1428571428571428 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 175px); position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cIaWs.framer-1qj74lz { gap: 0px; } .framer-cIaWs.framer-1qj74lz > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-cIaWs.framer-1qj74lz > :first-child { margin-top: 0px; } .framer-cIaWs.framer-1qj74lz > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 500
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"1420px",null,null]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHk_Eo9quD: React.ComponentType<Props> = withCSS(Component, css, "framer-cIaWs") as typeof Component;
export default FramerHk_Eo9quD;

FramerHk_Eo9quD.displayName = "About / Globe";

FramerHk_Eo9quD.defaultProps = {height: 500, width: 800};

addFonts(FramerHk_Eo9quD, [{explicitInter: true, fonts: []}, ...GlobeFonts], {supportsExplicitInterCodegen: true})